import { Box, Card, CardHeader, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import ResponsiveContent from './ResponsiveContent';

const BaseCard = ({
  title,
  headerComponent,
  width,
  children,
}: PropsWithChildren<{
  title?: string;
  headerComponent?: ReactNode;
  width?: string;
}>) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        maxWidth: width || '600px',
        borderRadius: '16px',
        width: '100%',
        height: 'fit-content',
      }}
    >
      <Box display="flex">
        {!!title && (
          <CardHeader
            title={title}
            sx={{ backgroundColor: theme.palette.primary.light, flexGrow: 1 }}
          />
        )}
        {!!headerComponent && headerComponent}
      </Box>
      <ResponsiveContent>{children}</ResponsiveContent>
    </Card>
  );
};

export default BaseCard;
