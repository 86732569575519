import { FormControl, MenuItem, Select } from '@mui/material';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import states from 'states-us';
import { useTranslations } from 'next-intl';

import FormField from './FormField';

const stateOptions = states.map((state) => (
  <MenuItem key={state.abbreviation} value={state.abbreviation}>
    {state.name}
  </MenuItem>
));

type Props = {
  name: string;
  label?: string;
  onChange?: any;
  disabled?: boolean;
};

const StateSelect = (props: UseControllerProps & Props) => {
  const t = useTranslations('StateSelect');
  const {
    formState: { errors },
  } = useFormContext();

  const { field } = useController(props);

  const getRenderValue = (stateCode: string) => {
    const state = states.find((val) => val.abbreviation === stateCode);

    return state?.name;
  };

  return (
    <FormField label={props.label} error={errors[props.name]}>
      <FormControl>
        <Select
          id="state"
          data-testid="state-select"
          error={!!errors[props.name]}
          {...field}
          value={field.value || ''}
          fullWidth
          displayEmpty
          onChange={(e) => {
            field.onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          disabled={props.disabled}
          size="small"
          renderValue={(value) => getRenderValue(value) || t('select-a-state')}
        >
          {stateOptions}
        </Select>
      </FormControl>
    </FormField>
  );
};

export default StateSelect;
