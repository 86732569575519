'use client';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import React from 'react';

const AppLocalizationProvider = ({ children }: { children: any }) => {
  return <LocalizationProvider dateAdapter={AdapterLuxon}>{children}</LocalizationProvider>;
};

export default AppLocalizationProvider;
