// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogLogs } from '@datadog/browser-logs';
// import { datadogRum } from '@datadog/browser-rum';

// if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production') {
//   datadogRum.init({
//     applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID!,
//     clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN!,
//     site: 'datadoghq.com',
//     service: 'emr',
//     env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
//     version: process.env.NEXT_PUBLIC_APP_VERSION,
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: false,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
//     allowedTracingUrls: ['https://emr.claritypediatrics.com'],
//   });
// }

if (
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ||
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging'
) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_LOG_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: 'emr',
    env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
