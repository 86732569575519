'use client';

import Box from '@mui/material/Box';
import { usePathname } from 'navigation';
import { ReactNode, useEffect, useRef } from 'react';

const AppContent = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const scrollContainerRef = useRef(null);

  const resetScrollPosition = () => {
    if (scrollContainerRef.current) {
      // @ts-ignore
      scrollContainerRef.current.scrollTop = 0; // Resetting the scrollTop to 0
    }
  };

  useEffect(() => {
    resetScrollPosition();
  }, [pathname]);

  return (
    <Box ref={scrollContainerRef} width="100%" height="100%">
      {children}
    </Box>
  );
};

export default AppContent;
