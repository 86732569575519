import { CardContent, styled } from '@mui/material';

import { mediaPhone } from '../../media';

const ResponsiveContent: any = styled(CardContent)`
  padding: 1em;

  ${mediaPhone} {
    margin-top: 0.5em;
    padding: 0.75em;
  }
`;

export default ResponsiveContent;
