import { Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import FormField from './FormField';
import StateSelect from './StateSelect';

const AddressFields = () => {
  const t = useTranslations('AddressFields');

  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container>
      <Grid size={12}>
        <FormField label={t('address-line-1')} error={errors.addressLine1}>
          <Controller
            control={control}
            name="addressLine1"
            render={({ field }) => (
              <TextField
                id="addressLine1"
                placeholder={t('enter-address-line-1')}
                variant="outlined"
                fullWidth
                {...field}
                onBlur={(event: any) => field.onChange(event.target.value.trim())}
                error={!!errors.addressLine1}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid size={12}>
        <FormField label={t('address-line-2')} error={errors.addressLine2}>
          <Controller
            control={control}
            name="addressLine2"
            render={({ field }) => (
              <TextField
                id="addressLine2"
                placeholder={t('enter-address-line-2')}
                variant="outlined"
                fullWidth
                {...field}
                onBlur={(event: any) => field.onChange(event.target.value.trim())}
                error={!!errors.addressLine2}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid size={12}>
        <FormField label={t('city')} error={errors.city}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextField
                id="city"
                placeholder={t('enter-city')}
                variant="outlined"
                fullWidth
                {...field}
                onBlur={(event: any) => field.onChange(event.target.value.trim())}
                error={!!errors.city}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid size={12}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <StateSelect name="state" label={t('state')} />
        </Stack>
      </Grid>
      <Grid size={12}>
        <FormField label={t('zip-code')} error={errors.zip}>
          <Controller
            control={control}
            name="zip"
            render={({ field }) => (
              <TextField
                id="zip"
                placeholder={t('enter-zip-code')}
                variant="outlined"
                fullWidth
                {...field}
                onBlur={(event: any) => field.onChange(event.target.value.trim())}
                error={!!errors.zip}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
    </Grid>
  );
};

export default AddressFields;
