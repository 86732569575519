import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormField from './FormField';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
};

const PasswordField = ({ name, label, placeholder }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <FormField label={label} error={errors[name]}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={!!errors[name]}
            {...field}
            placeholder={placeholder}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormField>
  );
};

export default PasswordField;
