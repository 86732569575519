import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export interface OptionType {
  label: string;
  inputValue?: string;
}

const filter = createFilterOptions<OptionType>();

const ControlledAutoComplete = ({
  field,
  label,
  valueOptions,
  placeholder,
  allowAdd,
}: {
  field: any;
  label?: string;
  valueOptions: OptionType[];
  placeholder?: string;
  allowAdd?: boolean;
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Autocomplete
      value={field.value}
      data-testid={field.name}
      onInputChange={(event, value, reason) => {
        if (event && event.type === 'blur') {
          field.onChange('');
        } else if (reason !== 'reset') {
          field.onChange(value);
        }
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          field.onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          field.onChange(newValue.inputValue);
        } else {
          field.onChange(newValue?.label);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting && allowAdd) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      id={field.name}
      options={valueOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      sx={{ width: '100%' }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!errors[field.name]}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default ControlledAutoComplete;
