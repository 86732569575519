'use client';

import Box from '@mui/material/Box';
import AppDrawer from 'components/AppDrawer';
import { AppContext } from 'context/AppProvider';
import { ReactNode, useContext, useMemo } from 'react';
import AppContent from 'components/AppContent';

const PageContent = ({ children }: { children: ReactNode }) => {
  const appContext = useContext(AppContext);

  const marginLeft = useMemo(() => {
    if (!appContext?.user) {
      return '0px';
    }

    return appContext?.drawerOpen ? '190px' : '64px';
  }, [appContext?.drawerOpen, appContext?.user]);

  return (
    <Box display="flex" width="100%" height="100%">
      {appContext?.user && <AppDrawer />}
      <div
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          marginLeft,
        }}
      >
        <AppContent>{children}</AppContent>
      </div>
    </Box>
  );
};

export default PageContent;
